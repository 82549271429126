const languages = [
    "FR",
    "EN",
    "NL",
    "ES",
    "DE",
    "IT",
    "PL",
    "PT",
    "RO",
    "SV",
    "KO",
    "ZH",
    "TH",
    "CS"
]

exports.languages = languages;